import React from "react";
import {Helmet} from "react-helmet";

const Head = () => {
    return (
        <div className="head">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>ЛК ОЗОН</title>
            </Helmet>
        </div>
    );
}

export default Head;
