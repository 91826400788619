import React from 'react';
import GoodsTableContainer from './GoodsTableContainer';

const GoodsTableWrapper = () => {
  return (
    <div className="block goods goods--scroll">
      <GoodsTableContainer/>
    </div>
  );
}

export default GoodsTableWrapper;
