import React from "react";
import Title from "../title/Title";
import TitleStatus from "../title/TitleStatus";
import OrderInfo from "./OrderInfo";
import OrderListWrapper from "./table/OrderListWrapper";
import {useLocation} from "react-router-dom";

const OrderItem = () => {
    const location = useLocation().state;
    debugger;
    return (
        <div className="container">
            <div className="block block__title block__order-status">
                <Title/>
                <TitleStatus/>
                <a className="payment title__payment">Посмотреть расчет заказа</a>
            </div>
            <OrderInfo/>
            <OrderListWrapper/>
        </div>
    );
}

export default OrderItem;
