import React from 'react';
import Main from "./components/main/Main";
import Head from "./components/head/Head";

const App = (props: { store: any; }) => {
  return (
      <>
            <Head/>
            <div className="app-wrapper">
                <Main store={props.store}/>
            </div>
      </>
  );
}

export default App;
