import React from "react";

export const COLUMNS: ({ Header: any; accessor: any;})[] = [
    {
        Header: '№ Заказа',
        accessor: 'orderNumber'
    },
    {
        Header: 'Дата формирования',
        accessor: 'date'
    },
    {
        Header: 'Сумма',
        accessor: 'summ'
    },
    {
        Header: 'Статус',
        accessor: 'status'
    },
    {
        Header: 'Город',
        accessor: 'city'
    },
    {
        Header: 'Sku',
        accessor: 'item.sku'
    },
    {
        Header: 'Имя',
        accessor: 'item.name'
    },
    {
        Header: 'Количество',
        accessor: 'item.quantity'
    }
]
