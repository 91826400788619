import React, {useEffect, useMemo, useState} from "react";
import GlobalSearch from "../../control/GlobalSearch";
import FiltersContainer from "../../control/filters/FiltersContainer";
import GoodsInterface from "./GoodsInterface";
import ContextMenu from "./cells/ContextMenu";
import {COLUMNS} from "./columns";
import {useGlobalFilter, useSortBy, useTable} from "react-table";
import API from '../api'



const GoodsTable = () => {
    const [products, setProducts] = useState([])
    
    const updateProducts = async () => {
        const [{products}, error] = await API.goods()
        if (error) {
            alert(error)
        }
        setProducts(products)
    }

    useEffect(() => {updateProducts()}, [])
    
    const tableInstance = useTable({
        columns: useMemo(() => COLUMNS, []),
        data: products,
    }, useGlobalFilter, useSortBy);

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setFilter, setGlobalFilter} = tableInstance;

    const {globalFilter} = state

    return (
        <>
            <GlobalSearch filter={globalFilter} setFilter={setGlobalFilter}/>
            <FiltersContainer setFilter={setFilter}/>
            <GoodsInterface/>
            <div className="goods__table-wrapper">
                <table className="table goods__table" {...getTableProps()}>
                    <thead>
                    {
                        headerGroups.map((headerGroup) => {
                            return (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    <td></td>
                                    {
                                        headerGroup.headers.map(column => (
                                            <td {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <button
                                                    className={column.isSorted ? (column.isSortedDesc ? 'arrow-btn arrow-btn--up' : 'arrow-btn arrow-btn--down') : 'arrow-btn'}
                                                    type="button">{column.render('Header')}</button>
                                            </td>
                                        ))}
                                </tr>
                            )
                        })
                    }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    <td className="table__checkbox-cell">
                                        <button className="goods__checkbox checkbox" type="button">✓</button>
                                    </td>
                                    {
                                        row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>

                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })
                                    }
                                    <td className="table__dots-cell dots-btn">
                                        <ContextMenu/>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default GoodsTable;
