import React from "react";

const OrderList = () => {
    return (
        <div className="order-list__table-wrapper">
            <table className="table table--order-list order-list__table">
                <thead>
                <tr>
                    <td>SKU</td>
                    <td>Наименование товара</td>
                    <td>Цена за единицу</td>
                    <td>Количество</td>
                    <td>Общая сумма</td>
                    <td></td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="table__sku-cell">17866</td>
                    <td className="table__order-name-cell">Смеситель AM.PM Like F0202600 монтируемый в стену с
                        гигиеническим душем и полкой
                    </td>
                    <td className="table__order-price-cell">68 360 ₽</td>
                    <td className="table__order-quantity-cell">
                        <div className="quantity">
                            <button className="quantity__minus" type="button">-</button>
                            <label><input className="quantity__input" type="text" value="123"/></label>
                            <button className="quantity__plus" type="button">+</button>
                        </div>
                    </td>
                    <td className="table__order-summ-cell">683 600 ₽</td>
                    <td className="table__order-delete-cell">
                        <button className="btn-delete" type="button">Удалить</button>
                    </td>
                </tr>
                <tr>
                    <td className="table__sku-cell">17866</td>
                    <td className="table__order-name-cell">Смеситель AM.PM Like F0202600 монтируемый в стену с
                        гигиеническим душем и полкой
                    </td>
                    <td className="table__order-price-cell">68 360 ₽</td>
                    <td className="table__order-quantity-cell">
                        <div className="quantity">
                            <button className="quantity__minus" type="button">-</button>
                            <label><input className="quantity__input" type="text" value="123"/></label>
                            <button className="quantity__plus" type="button">+</button>
                        </div>
                    </td>
                    <td className="table__order-summ-cell">683 600 ₽</td>
                    <td className="table__order-delete-cell">
                        <button className="btn-delete" type="button">Удалить</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default OrderList;
