import React from "react";
import Header from "../header/Header";
import {Route, withRouter} from "react-router-dom";
import Orders from "./orders/Orders";
import Goods from "./goods/Goods";
import Analytics from "./analytics/Analytics";
import Settings from "./settings/Settings";
import OrderItem from "./orderItem/OrderItem"

class Main extends React.Component<any, any> {

    render() {
        return (
            <>
                <Header/>
                <main className="content-wrapper">
                    <section className="section-main">
                        <Route path="/goods" render={() => <Goods/>} />
                        <Route path="/orders" render={() => <Orders/>} />
                        <Route path="/analytics" render={() => <Analytics/>} />
                        <Route path="/settings" render={() => <Settings/>} />
                        <Route path="/order-item" render={() => <OrderItem/>} />
                    </section>
                </main>
            </>
        );
    }
}

export default Main;
