import React from "react";
import OrdersTableContainer from "./OrdersTableContainer";

const OrdersTableWrapper = () => {
    return (
        <>
            <div className="block">
                <OrdersTableContainer/>
            </div>
        </>
    );
}

export default OrdersTableWrapper;
