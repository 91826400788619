import React from 'react';

const GoodsInterface = () => {
  return (
    <div className="goods__interface block__wrapper">
      <button className="goods__checked" type="button">Выбрано 2 товара</button>
      <button className="btn-delete" type="button">Удалить</button>
    </div>
  );
}

export default GoodsInterface;
