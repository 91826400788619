import React from "react";

const Analytics = () => {
    return (
        <div className="container" id="analytics">

        </div>
    )
}

export default Analytics;
