import React from "react";

const Ratio = () => {
    return (
        <section className="section-half"><h2 className="title title__options title--subtitle">Коэффициенты</h2>
            <div className="options">
                <div className="options__row">
                    <span className="options__description">Коэффициент промо:</span>
                    <label className="options__label">
                        <input className="options__input" type="text" placeholder="Не указан"/>
                    </label>
                </div>
                <div className="options__row">
                    <span className="options__description">Коэффициент промо:</span>
                    <label className="options__label">
                        <input className="options__input" type="text" placeholder="Не указан"/>
                    </label>
                </div>
                <div className="options__row">
                    <span className="options__description">Коэффициент промо:</span>
                    <label className="options__label">
                        <input className="options__input" type="text" placeholder="Не указан"/>
                    </label>
                </div>
                <div className="options__row">
                    <span className="options__description">Коэффициент промо:</span>
                    <label className="options__label">
                        <input className="options__input" type="text" placeholder="Не указан"/>
                    </label>
                </div>
            </div>
        </section>
    );
}

export default Ratio;
