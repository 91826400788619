import React from "react";

const GlobalSearch = ({filter, setFilter} : {filter: any, setFilter: any}) => {
    return (
      <div className="block block__search">
          <h2 className="title title__subtitle title--subtitle">Поиск</h2>
          <div className="block__wrapper">
              <label>

                  <input value={filter || ''}
                         onChange={(e) => setFilter(e.target.value)}
                         type="text"
                         className="input input__search input--search"
                         placeholder="Название"
                  />
              </label>
          </div>
      </div>
    );
}

export default GlobalSearch;
