import React from "react";
import Title from "../title/Title";
import OrdersTableWrapper from "./table/OrdersTableWrapper";

const Orders = () => {
    return (
        <div className="container" id="orders">
            <div className="block block__title-order">
                <Title/>
            </div>
            <OrdersTableWrapper/>
        </div>
    )
}

export default Orders;
