const SET_FILTERS = 'SET_FILTERS';

let initialState = {
        headers: [
            {
                "name": "ID товара",
                "type": "input"
            },
             {
                "name": "ID товара ozon",
                "type": "input"
            },
             {
                "name": "Название товара",
                "type": "input"
            },
             {
                "name": "Тип кабинета",
                "type": "dropdown",
                "items": ["Мелкогабаритный", "Крупногабаритный"]
            },
            {
                "name": "Категория EDIS",
                "type": "dropdown",
                "items": [true, false]
            },
            {
                "name": "Категория товара",
                "type": "dropdown",
                "items": ["A", "B", "C"]
            },
             {
                "name": "Товарная категория",
                "type": "dropdown",
                "items": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
            },
             {
                "name": "Коэффициент промо",
                "type": "dropdown",
                "items": [0.15, 0.16, 0.17]
            },
            {
                "name": "Коэфф. cпец.цены",
                "type": "dropdown",
                "items": [0.15, 0.16, 0.17]
            },
            {
                "name": "Коэффициент промокодов",
                "type": "dropdown",
                "items": [0.15, 0.16, 0.17]
            },
            {
                "name": "Коэффициент оборачиваемости",
                "type": "dropdown",
                "items": [0.15, 0.16, 0.17]
            }
        ]
}

const filtersReducer = (state = initialState, action: { type: any; }) => {
    switch (action.type) {
        case SET_FILTERS: {
            return state;
        }

        default:
            return state;
    }
}

export default filtersReducer;
