import React from "react";
import OrdersOptions from "../orders/OrdersOptions";

const BottomPagination = (
    {nextPage, previousPage, canNextPage, canPreviousPage} :
    {nextPage: any, previousPage: any, canNextPage: any, canPreviousPage: any}) => {
    return (
        <div className="block pagination">
            <div className="pagination__wrapper">
                <OrdersOptions/>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>
            </div>
        </div>
    );
}

export default BottomPagination;
