import React from "react";
import Title from "../title/Title";
import Ratio from "./Ratio";
import SettingsOrder from "./SettingsOrder";

const Settings = () => {
    return (
        <div className="container" id="settings">
            <div className="block block__title">
                <Title/>
            </div>
            <div className="block block__wrapper block__options">
                <Ratio/>
                <SettingsOrder/>
            </div>
            <div className="block block__button-wrapper">
                <button className="btn btn__cancel" type="button">Отменить изменения</button>
                <button className="btn btn--black" type="button">Сохранить изменения</button>
            </div>
        </div>
    )
}

export default Settings;
