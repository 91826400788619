import React from "react";
import Dropdown from "react-dropdown";

const Filters = (props: any) => {
    let filters = props;
    console.log(filters);

    return (

        <div className="block block__filter dropdown">
            <div className="block__wrapper dropdown__wrapper">
                {
                    props.inputFilter.map((input: any) =>
                        <label>
                            <input
                                className="input--filter input--ozon input"
                                type="text"
                                placeholder={input.name} />
                        </label>
                    )
                }
                {
                    props.dropdownFilter.map((dropdown: any) =>
                        <Dropdown
                            className="dropdown__item"
                            controlClassName="dropdown__button"
                            menuClassName="dropdown__list"
                            options={dropdown.items}
                            placeholder={dropdown.name}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default Filters;
