import React from "react";

const ContextMenu = () => {
    return (
        <>
            <button className="dots-btn__button dots-btn__button--active goods__context" type="button">•
            </button>
            <div className="dots-btn__wrapper">
                <ul className="dots-btn__list">
                    <li>
                        <button className="dots-btn__option dots-btn__option--new" type="button">Перенести в
                            новинки
                        </button>
                    </li>
                    <li>
                        <button className="dots-btn__option dots-btn__option--actual"
                                type="button">Перенести в действующую матрицу
                        </button>
                    </li>
                    <li>
                        <button className="dots-btn__option dots-btn__option--archive"
                                type="button">Перенести в архив
                        </button>
                    </li>
                    <li>
                        <button className="dots-btn__option dots-btn__option--delete"
                                type="button">Удалить
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default ContextMenu;
