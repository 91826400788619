import React from "react";

const OrderInfo = () => {
    return (
        <div className="block block__order-info order-info">
            <p className="order-info__city">Город:<span> Санкт-Петербург</span></p>
            <p className="order-info__position">Количество позиций:<span> 4</span></p>
            <p className="order-info__summ">Сумма заказа:<span> 1 862 990 ₽</span></p>
        </div>
    );
}

export default OrderInfo;
