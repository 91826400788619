import React from "react";
import {connect} from "react-redux";
import Filters from "./Filters";

let mapStateToProps = (state: any) => {
    return {
        inputFilter: state.filters.headers.filter(function(e: { type: string; }){
            return e.type == "input";
        }),
        dropdownFilter: state.filters.headers.filter(function(e: { type: string; }){
            return e.type == "dropdown";
        })
    }
}

let mapDispatchToProps = () => {
}


export default connect(mapStateToProps, mapDispatchToProps)(Filters);
