import axios from 'axios'

const API = () => {
    const apiInst = axios.create({
        baseURL: 'https://virtserver.swaggerhub.com/Aquaart/MarketPlace/1.0.0',
    })

    return {
        async goods() {
            try {
                const resposnse = await apiInst.get('/catalog')
                return [resposnse.data, '']
            } catch {
                const failedRes = {columns: [], filters: [], products: []}
                return [failedRes, 'Unable to get goods from server']
            }
        },
    }
}

export default API()
