import React from "react";
import GoodsTable from "./GoodsTable";

class GoodsTableContainer extends React.Component {

    render () {
        return (
            <GoodsTable/>
        )
    }
}

export default GoodsTableContainer;
