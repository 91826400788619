import React from "react";

const SettingsOrder = () => {
    return (
        <section className="section-half">
            <h2 className="title title__options title--subtitle">Настройки заказа</h2>
            <div className="options options--big">
                <div className="options__row">
                    <div className="options__text">
                        <p>Автоматическое размещение заказа:</p>
                        <p className="options__text--small">Возможность автоматического размещения (вне зависимости от суммы заказа)</p>
                    </div>
                    <button className="switch switch__options" type="button"></button>
                </div>
                <div className="options__row"><span className="options__description">Пороговая сумма заказа без подтверждения:</span><label><input
                    className="options__input" type="text" placeholder="150 000"/></label>
                </div>
            </div>
        </section>
    );
}

export default SettingsOrder;
