import React from "react";
import OrderList from "./OrderList";

const OrderListWrapper = () => {
    return (
        <>
            <div className="block block__order-list order-list">
                <h2 className="title title__order-list title--subtitle">Информация о заказе</h2>
                <OrderList/>
            </div>

            <div className="block block__button-wrapper">
                <button className="btn btn__cancel" type="button">Отменить заказ</button>
                <button className="btn btn--black" type="button">Подтвердить заказ</button>
            </div>
        </>
    );
}

export default OrderListWrapper;
