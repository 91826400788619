import React from "react";

const OrdersOptions = () => {
    return (
        <div className="pagination__options">
            <button className="pagination__button" type="button">10</button>
            <button className="pagination__button pagination__button--active" type="button">25</button>
            <button className="pagination__button" type="button">50</button>
        </div>
    );
}

export default OrdersOptions;
