import React, {useMemo} from "react";
import {useTable, useSortBy, usePagination} from 'react-table';
import {COLUMNS} from "./columns";
import ORDER_DATA from "./ORDER_DATA.json";
import BottomPagination from "../../pagination/BottomPagination";
import {useHistory} from 'react-router-dom';

const OrdersTableContainer = () => {
    const columns = useMemo(() => COLUMNS, []);
    const data = useMemo(() => ORDER_DATA, []);

    const tableInstance = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: ["city", "item.sku", "item.name", "item.quantity"]
        }
    }, useSortBy, usePagination);

    const {getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canNextPage, canPreviousPage, prepareRow} = tableInstance;

    const history = useHistory();
    const handleRowClick = (row: any) => {
        history.push(`/order-item/ + ${row.values.orderNumber}`,{params: row.values})
    }


    return (
        <>
            <div className="goods__wrapper">
                <table className="table table--order" {...getTableProps()}>
                    <thead>
                    {
                        headerGroups.map((headerGroup) => {
                            return (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (
                                            <td {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <button
                                                    className={column.isSorted ? (column.isSortedDesc ? 'arrow-btn arrow-btn--up' : 'arrow-btn arrow-btn--down') : 'arrow-btn'}
                                                    type="button">{column.render('Header')}</button>
                                            </td>
                                        ))}
                                </tr>
                            );
                        })
                    }
                    </thead>
                    <tbody  {...getTableBodyProps()}>
                    {
                        page.map(row => {
                            prepareRow(row)
                            return (
                                    <tr {...row.getRowProps()}
                                        className={row.values.status == "Подтвержден" ? 'table__cell' : 'table__cell table__cell--warning'}
                                        onClick={() => handleRowClick(row)}>
                                        {
                                            row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
            <BottomPagination nextPage={nextPage} previousPage={previousPage} canNextPage={canNextPage} canPreviousPage={canPreviousPage}/>
        </>
    );
}

export default OrdersTableContainer;
