import React from "react";
import {NavLink} from "react-router-dom";

const NavMenu = () => {
    return (
        <nav className="header__menu">
            <NavLink className="header__menu-link" to="/goods">Товары</NavLink>
            <NavLink className="header__menu-link" to="/orders">Заказы</NavLink>
            <NavLink className="header__menu-link" to="/analytics">Аналитика</NavLink>
            <NavLink className="header__menu-link" to="/settings">Настройки</NavLink>
            <NavLink className="header__menu-link" to="/order-item">Временная ссылка на заказ</NavLink>
        </nav>
    )
}

export default NavMenu;
