import {combineReducers, createStore} from "redux";
import filtersReducer from "./filtersReducer";

let reducers = combineReducers({
    filters: filtersReducer
});

/* Костыль для объявления window */
declare global {
    interface Window {
        store: any;
    }
}

let store = createStore(reducers);

window.store = store;

export default store;
