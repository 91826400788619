import {type} from "os";

export const COLUMNS: ({ Header: any; accessor: any } | { Header: any; accessor: any; Cell: any;})[] = [
    {
        Header: 'ID Товара',
        accessor: 'id'
    },
    {
        Header: 'ID Товара Ozon',
        accessor: 'sku'
    },
    {
        Header: 'Название',
        accessor: 'name'
    },
    {
        Header: 'Тип кабинета',
        accessor: 'account',
    },
    {
        Header: 'Категория EDIS',
        accessor: 'categories.isEdis',
        Cell: ({value} : {value: boolean}) => {
            return <button type="button"  className={(value === true ? 'switch switch--active goods__switch': 'switch goods__switch') }></button>
        }
    },
    {
        Header: 'Категория товара',
        accessor: 'categories.level'
    },
    {
        Header: 'Товарная категория',
        accessor: 'categories.department'
    },
    {
        Header: 'Коэфф. промо',
        accessor: 'ratios.promo',
        Cell: ({value} : {value: number}) => {
            return <input className="input input--params" value={value} disabled/>
        }
    },
    {
        Header: 'Коэфф. спец.цены',
        accessor: 'ratios.special',
        Cell: ({value} : {value: number}) => {
            return <input className="input input--params" value={value} disabled/>
        }
    },
    {
        Header: 'Коэфф. промокодов',
        accessor: 'ratios.code',
        Cell: ({value} : {value: number}) => {
            return <input className="input input--params" value={value} disabled/>
        }
    },
    {
        Header: 'Коэфф. оборачиваемости',
        accessor: 'ratios.turn',
        Cell: ({value} : {value: number}) => {
            return <input className="input input--params" value={value} disabled/>
        }
    }
]
