import React from "react";
import Logo from "../../assets/img/logo.svg"
import NavMenu from "../navMenu/NavMenu";

const Header = () => {
    return (
        <header className="header">
            <div className="container container--wide header__wrapper">
                <a className="header__logo">
                    <img src={Logo}/>
                </a>
                <NavMenu/>
            </div>
        </header>
    );
}

export default Header;
