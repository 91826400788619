import React from "react";
import Title from "../title/Title";
import FileUpload from "../fileUpload/FileUpload";
import GoodsTableWrapper from "./table/GoodsTableWrapper";

const Goods = () => {
    return (
        <div className="container" id="goods">
            <div className="block block__title">
                <Title/>
                <FileUpload/>
            </div>
            <GoodsTableWrapper/>
        </div>
    );
}

export default Goods;
