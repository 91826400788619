import React from "react";

const FileUpload = () => {
    return (
        <div className="upload">
            <button className="btn btn__upload btn--upload" type="button">Загрузить файл</button>
            <button className="btn btn--download" type="button">Скачать файл</button>
        </div>
    );
}

export default FileUpload;
